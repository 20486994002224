var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a9d18ce1bbccaa30777489edf96f0f36f79c4792"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_VERCEL_ENV || 'development'

Sentry.init({
  dsn: ['preview', 'production'].includes(SENTRY_ENVIRONMENT) ? SENTRY_DSN : undefined, // disable Sentry for all other envs
  environment: SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ['screen-api.eatwith.com', 'screen-api.vizeater.co'], // TODO: must allow header for CORS in NGINX config
    }),
  ],
  tracesSampleRate: 0.05,
  denyUrls: [
    // Intercom
    /js\.intercomcdn\.com/i,
    // GTM injected scripts
    /twitter/i,
    /hotjar/i,
    /bing/i,
    /googleads/i,
    /stats\.g\.doubleclick\.net/i,
    // Facebook blocked
    /connect\.facebook\.net/i,
    // Cloudflare
    /cdn-cgi\/rum/i, // I believe this is the performance script from Cloudflare
    // Browser extensions
    /extensions\//i,
    /^safari-extension/i,
    /^chrome:\/\//i,
    // DNS CNAME pointing to our website
    /traveldubaifriends\.com/i,
  ],
  // release: process.env.SENTRY_RELEASE // automatically settled by Next/Vercel
  ignoreErrors: [
    'Cookies are not enabled in current environment.',
    'Non-Error promise rejection captured',
    'Illegal invocation',
    "Failed to execute 'removeChild'",
  ],
})
